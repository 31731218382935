import React from 'react'
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Legend, } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);

function Task(props) {
    // const [isLoaded, setIsLoaded] = useState(false)

    const { dataCampaign } = props;
    const dataTask = dataCampaign.campaign.task
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataTask.target;

    // Images data
    const banner = dataTask.background_picture;
    const updateTime = dataUser.updated_time;

    const monthArr = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate + ' ' + monthArr[dataMonth - 1] + ' ' + dataYear + ' ' + dataTime)
    }

    const doughnutChart = () => {
        const reachedColor = '#199F98'
        const unreachedColor = '#D6D8E3'

        let reachedVal = !!dataUser.progress ? dataUser.progress : 0
        let calcnreachedVal = (!!campaignTarget.target ? campaignTarget.target : 0) - reachedVal
        let unreachedVal = (calcnreachedVal < 0) ? 0 : calcnreachedVal

        const data = {
            datasets: [
                {
                    data: [reachedVal, unreachedVal],
                    backgroundColor: [
                        reachedColor,
                        unreachedColor,
                    ],
                    borderWidth: 0,
                },
            ],
        };

        const options = {
            animation: {
                duration: 0
            }
        }

        return (
            <div className="doughnutBox" style={{ width: window.innerWidth / 1.5 + 'px', height: window.innerWidth / 1.5 + 'px' }}>
                <div className="doughnutDetailBox" style={{ width: window.innerWidth / 1.85 + 'px', height: window.innerWidth / 1.85 + 'px' }}>
                    {
                        unreachedVal ?
                            <React.Fragment>
                                <p>คุณทำได้ </p>
                                <p><span>{reachedVal}</span> / {campaignTarget.target}</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <p>คุณทำครบ </p>
                                <p><span>{campaignTarget.target}</span></p>
                            </React.Fragment>

                    }
                </div>
                <Doughnut data={data} options={options} />
            </div>
        )
    }

    return (
        <div className="App-task-V2">
            <header className="App-banner">
                {
                    banner ?
                        <img src={banner} alt="banner" />
                        :
                        <div className="noimg">
                            No Image
                        </div>

                }
            </header>

            <div className="App-content">
                <p>รับ {
                    (campaignTarget.reward_type === 'sfdc') ?
                        <React.Fragment>
                            <span>{(campaignTarget.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)} คะแนน</span>
                        </React.Fragment>
                        :
                        <span>
                            {campaignTarget.reward_name}
                        </span>
                } เมื่อจบรายการ
                </p>

                {
                    doughnutChart()
                }
            </div>

            <div className="App-status">
                <div className="status-content">
                    <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                </div>
            </div>
        </div>
    )

}

export default Task
